import { API_URL } from "../constants/configs"

type IServicePayload = {
  service: string[]
  name: string
  email: string
  message: string
}

export const createService = async (data: IServicePayload) => {
  return await fetch(API_URL + "/api/services", {
    method: "post",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    body: JSON.stringify(data),
  })
}
