import React from "react"
import "./JobDescription.css"

interface JobDescriptionProps {
  title: string
  content: string[]
}

const JobDescription = (props: JobDescriptionProps) => {
  return (
    <div className='role-section mb-[48px]'>
      <p className='font-semibold mb-2 font-work_sans'>{props.title}</p>
      <ul className='list-disc pl-3 leading-6'>
        {props.content.map((item, index) => {
          return (
            <li key={index} className='body-text-1--regular'>
              {item}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default JobDescription
