export const FOLLOW_US = [
  {
    label: "LinkedIn",
    route: "https://www.linkedin.com/company/delta-cognition/",
  },
]

export const FOOTER_CONTENTS = [
  {
    label: "Terms of Use",
    route: "#",
  },
  {
    label: "Privacy Policy",
    route: "#",
  },
  {
    label: "Language",
    route: "English",
  },
  {
    label: "Company",
    route: "© 2023 by Delta Cognition",
  },
]

export const FOOTER_ADDRESS = [
  {
    label: "38 Edwin Close Manor Lakes VIC 3024 Australia",
    country: "Australia",
  },
  {
    label: "18E Cong Hoa, Ward 4, District Tan Binh, Ho Chi Minh City",
    country: "Vietnam",
  },
]
