import React from "react"
import "./CareerPage.css"

import CareerBanner from "../../widgets/v0.1/0.1.1/CareerBanner/CareerBanner"
import ApplicationModule from "../../widgets/v0.1/0.1.1/ApplicationModule/ApplicationModule"
import careerData from "../../constants/contents/career.json"

type Props = {}

type States = {
  apply: boolean
  location: { id: string; check: boolean }[]
  category: { id: string; check: boolean }[]
  clearAll: boolean
  selectionControl: {
    id: number
    select: boolean
    checkbox: {
      id: string
      check: boolean
    }[]
  }[]
}

class CareerPage extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props)

    this.state = {
      apply: false,
      location: [],
      category: [],
      clearAll: false,
      selectionControl: [
        {
          id: 0,
          select: false,
          checkbox: careerData.module.positions
            .map((item) => item.address)
            .filter((val, id, array) => array.indexOf(val) == id)
            .map((e) => {
              return {
                id: e,
                check: false,
              }
            }),
        },
        {
          id: 1,
          select: false,
          checkbox: careerData.module.positions
            .map((item) => item.role)
            .filter((val, id, array) => array.indexOf(val) == id)
            .map((e) => {
              return {
                id: e,
                check: false,
              }
            }),
        },
      ],
    }
    this.onApply = this.onApply.bind(this)
    this.setSelectionControl = this.setSelectionControl.bind(this)
  }

  onApply(status: boolean) {
    this.setState({ apply: status })
  }

  setSelectionControl(
    data: {
      id: number
      select: boolean
      checkbox: {
        id: string
        check: boolean
      }[]
    }[],
  ) {
    this.setState({ selectionControl: data })
  }

  render() {
    return (
      <div className='career-page'>
        {!this.state.apply && (
          <CareerBanner
            {...{
              SetLocation: (selectedData: { id: string; check: boolean }[]) => {
                this.setState((e) => {
                  return { ...e, location: selectedData }
                })
              },
              SetCategory: (selectedData: { id: string; check: boolean }[]) => {
                this.setState((e) => {
                  return { ...e, category: selectedData }
                })
              },
              data: careerData,
              selectionControl: this.state.selectionControl,
              setSelectionControl: this.setSelectionControl,
            }}
          />
        )}
        <ApplicationModule
          location={this.state.location}
          category={this.state.category}
          apply={this.state.apply}
          onApply={this.onApply}
          data={careerData}
        />
      </div>
    )
  }
}

export default CareerPage
