type ILoginPayload = {
  userName: string
  password: string
}

export const login = async (data: ILoginPayload) => {
  if (data.userName === "deltacognition" && data.password === "tHx3WWEv9PQc") {
    return new Response()
  }
  return Response.error()
}
