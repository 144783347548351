import React, { useEffect } from "react"
import "./Form.css"
import TextField from "../TextField/TextField"
import { createApplicant } from "../../../../../services/appliant"
import validator from "validator"
import Successful from "../Successful/Successful"
import { useNavigate } from "react-router-dom"

const HINT_FOR_LINK = {
  id: "portfolioID",
  content:
    "Link of your github, linkedin or any url that can provide more detail about you or your projects",
  icon: "./assets/career/hint-icon.svg",
}

const ROLE_DETAILS_FORM = {
  title: "Apply for this position",
  textInput: [
    {
      id: "name",
      type: "text",
      hint: "Your name",
      text: "",
      bgColor: "bg-white",
    },
    {
      id: "email",
      type: "email",
      hint: "Your email",
      text: "",
      bgColor: "bg-white",
    },
    {
      id: "phone",
      type: "tel",
      hint: "Your phone number",
      text: "",
      bgColor: "bg-white",
    },
  ],
  link: {
    id: "link",
    type: "text",
    hint: "Portfolio link",
    text: "",
    bgColor: "bg-white",
  },
  optional: {
    id: "description",
    type: "text",
    hint: "Tell us more about yourself (optional)",
    detail: "Maximum 512 characters",
    max: 512,
    text: "",
  },
}

// Updates the height of a <textarea> when the value changes.
const useAutosizeTextArea = (textAreaRef: HTMLTextAreaElement | null, value: string) => {
  useEffect(() => {
    if (textAreaRef) {
      textAreaRef.style.height = "35px"
      const scrollHeight = textAreaRef.scrollHeight

      textAreaRef.style.height = scrollHeight + 5 + "px"
    }
  }, [textAreaRef, value])
}

const Form = (props: {
  position: string
  applyCondition: boolean
  setApplyCondition: (check: boolean) => void
}) => {
  const [message, setMessage] = React.useState<{
    message: string
    messageType: "error" | "success"
    status: "none" | "loading" | "done"
  }>({
    message: "",
    messageType: "success",
    status: "none",
  })
  // we need a list of empty error data first
  const [nameError, setNameError] = React.useState("")
  const [emailError, setEmailError] = React.useState("")
  const [phoneError, setPhoneError] = React.useState("")
  const [fileError, setFileError] = React.useState("")
  const [linkError, setLinkError] = React.useState("")

  // setup logical detail for error
  const nameValidation = (value: string) => {
    if (value.length === 0) {
      setNameError("Name is required")
      return false
    }
    if (!validator.isAlpha(value)) {
      setNameError("Please use Alphabet only")
      return false
    }
    setNameError("")
    return true
  }
  const emailValidation = (value: string) => {
    if (value.length === 0) {
      setEmailError("Email is required")
      return false
    }
    if (!validator.isEmail(value)) {
      setEmailError("Please enter a correct email format. Example: abc@gmail.com")
      return false
    }
    setEmailError("")
    return true
  }
  const phoneValidation = (value: string) => {
    if (value.length === 0) {
      setPhoneError("Phone is required")
      return false
    }
    if (!validator.isMobilePhone(value)) {
      setPhoneError("This is not a phone number")
      return false
    }
    setPhoneError("")
    return true
  }

  const fileValidation = (value: File | undefined) => {
    if (value === undefined) {
      setFileError("Your CV is required")
      return false
    }
    setFileError("")
    return true
  }

  const linkValidation = (value: string) => {
    const regex = new RegExp("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")
    if (value.length === 0) {
      setLinkError(
        "Sorry, but we require applicant to have portfolio link such as GitHub to show their skills through projects",
      )
      return false
    }
    if (!regex.test(value)) {
      setLinkError("Please enter a valid web URL")
      return false
    }
    setLinkError("")
    return true
  }

  const errorMessageList = [
    {
      id: "name",
      message: nameError,
    },
    {
      id: "email",
      message: emailError,
    },
    {
      id: "phone",
      message: phoneError,
    },
    {
      id: "link",
      message: linkError,
    },
  ]

  const validationList = [
    {
      id: "name",
      validationFunc: nameValidation,
    },
    {
      id: "email",
      validationFunc: emailValidation,
    },
    {
      id: "phone",
      validationFunc: phoneValidation,
    },
    {
      id: "link",
      validationFunc: linkValidation,
    },
  ]

  const inputFile = React.useRef<HTMLInputElement>(null)
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null)

  const [file, setFile] = React.useState<File>()
  const [numChar, setNumChar] = React.useState<number>(0)
  const [value, setValue] = React.useState("")

  const [applicant, setApplicant] = React.useState<{
    name: string
    email: string
    phone: string
    link: string
    description: string
  }>({
    name: "",
    email: "",
    phone: "",
    link: "",
    description: "",
  })

  const validation = () => {
    nameValidation(applicant.name)
    emailValidation(applicant.email)
    phoneValidation(applicant.phone)
    fileValidation(file)
    linkValidation(applicant.link)
  }

  useAutosizeTextArea(textAreaRef.current, value)
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0])
    }
    fileValidation(event.target.files ? event.target.files[0] : undefined)
  }

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value
    setNumChar(val.length)
    setValue(val)
    setApplicant({ ...applicant, description: val })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setMessage({ ...message, status: "loading" })
    if (
      applicant.name !== "" &&
      applicant.email !== "" &&
      applicant.phone !== "" &&
      applicant.link !== "" &&
      applicant.description !== "" &&
      file
    ) {
      const data = new FormData()
      data.append("name", applicant.name)
      data.append("email", applicant.email)
      data.append("position", props.position)
      data.append("phone", applicant.phone)
      data.append("portfolioLink", applicant.link)
      data.append("description", applicant.description)
      data.append("file", file || "")
      createApplicant(data)
        .then((res) => {
          if (res.status === 200) {
            setMessage({
              message: "Thank you for your submit. We will reply you soon",
              messageType: "success",
              status: "done",
            })
            setApplicant({
              name: "",
              email: "",
              phone: "",
              link: "",
              description: "",
            })
            setFile(undefined)
            // set to true -> move to successful widget, and hide the application form
            props.setApplyCondition(true)
          }
        })
        .catch(() => {
          // set to false -> stay in application form and display the errors
          props.setApplyCondition(false)
          setMessage({
            message: "Failed to send.",
            messageType: "error",
            status: "none",
          })
        })
    } else {
      validation()
      props.setApplyCondition(false)
      setMessage({
        message: "Please fill all the data first.",
        messageType: "error",
        status: "none",
      })
    }
  }
  const navigate = useNavigate()
  return (
    <div>
      {props.applyCondition == false ? (
        <form
          className={
            props.applyCondition == false ? "form-container bg-[#F4F5F6] rounded-[16px]" : "hidden"
          }
          onSubmit={handleSubmit}
        >
          <h4 className='pb-8'>{ROLE_DETAILS_FORM.title}</h4>
          {ROLE_DETAILS_FORM.textInput.map((item, index) => {
            return (
              <TextField
                key={index}
                {...item}
                setValue={setApplicant}
                value={applicant}
                validateFunc={(e) => validationList.find((e) => e.id == item.id)?.validationFunc(e)}
                error={errorMessageList.find((e) => e.id == item.id)?.message}
              />
            )
          })}
          <p className='mb-2 font-open_sans text-xs leading-4 text-[#6B7181]'>RESUME/CV</p>
          <div className='flex gap-4 mb-8 flex-col tablets:flex-row tablets:items-center'>
            <input
              ref={inputFile}
              onChange={handleFileUpload}
              accept='.pdf, .doc, .docx'
              type='file'
              className='hidden'
              required
            />
            {file === undefined && (
              <button
                type='button'
                className='form-upload-btn'
                onClick={() => inputFile.current?.click()}
              >
                Upload
              </button>
            )}
            <span
              className={
                file === undefined
                  ? "text-[#6B7181] font-open_sans text-xs leading-4"
                  : "text-black"
              }
            >
              {file === undefined ? "(File types: pdf, doc, docx)" : file.name}
            </span>
            {file !== undefined && (
              <button className='text-primary' onClick={() => setFile(undefined)}>
                Remove
              </button>
            )}
            {file !== undefined && (
              <button className='text-primary' onClick={() => inputFile.current?.click()}>
                Replace
              </button>
            )}
          </div>
          {fileError && <p className='text-red-500 text-xs mt-[-20px] mb-[20px]'>{fileError}</p>}
          <TextField
            {...ROLE_DETAILS_FORM.link}
            setValue={setApplicant}
            validateFunc={(e) => validationList.find((e) => e.id == "link")?.validationFunc(e)}
            hintDetail={HINT_FOR_LINK}
            value={applicant}
            error={linkError}
          />
          <div className='mb-8 h-fit'>
            <label htmlFor='id' className='text-[#6B7181] text-[12px] font-open_sans'>
              {ROLE_DETAILS_FORM.optional.hint.toUpperCase()}
            </label>
            <textarea
              className={
                "w-full max-w-[100%] p-[12px] mb-8 mt-[12px] min-h-[110px] rounded-[8px] outline-none resize-none overflow-hidden " +
                (numChar > 1 ? "" : "h-[35px]")
              }
              name={ROLE_DETAILS_FORM.optional.id}
              ref={textAreaRef}
              maxLength={ROLE_DETAILS_FORM.optional.max}
              onChange={handleChange}
              rows={1}
              value={applicant.description}
            />
            <div className='flex justify-between text-[12px]'>
              <p className='text-[#6B7181] font-open_sans'>
                {ROLE_DETAILS_FORM.optional.detail.toUpperCase()}
              </p>
              <p className='py-1 px-3 border rounded-[14px] border-[#16171A]'>
                {numChar?.toString()}/{ROLE_DETAILS_FORM.optional.max.toString()}
              </p>
            </div>
          </div>
          <div className='text-right mt-8 flex justify-end'>
            <button className='form-submit-btn' onClick={() => validation()}>
              {message.status === "loading" && (
                <svg
                  className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  ></path>
                </svg>
              )}
              Submit application
            </button>
          </div>
          <p
            className={[
              "ml-2 text-right w-full",
              message.messageType === "error" ? "text-red-500" : "text-green-500",
            ].join(" ")}
          >
            {message.message}
          </p>
        </form>
      ) : (
        <Successful
          {...{
            title: "Congrats! Your application is well received!",
            content: "We will contact you shortly to proceed!",
            firstButtonName: "Back to home page",
            secondButtonname: "Continue to browse jobs",
            firstButton() {
              navigate("/")
            },
            secondButton() {
              window.location.reload()
            },
          }}
        />
      )}
    </div>
  )
}

export default Form
