import React from "react"
import "./TextField.css"
import "react-tooltip/dist/react-tooltip.css"
import { Tooltip as ReactTooltip } from "react-tooltip"

interface TextFieldProps {
  id: string
  hint: string
  text: string
  type: string
  bgColor: string
  error: string | undefined
  hintDetail?: {
    id: string
    content: string
    icon: string
  }
  value?: {
    name: string
    email: string
    phone: string
    link: string
    description: string
  }
  validateFunc: (validateData: string) => void
  setValue?: React.Dispatch<
    React.SetStateAction<{
      name: string
      email: string
      phone: string
      link: string
      description: string
    }>
  >
}

const TextField = (props: TextFieldProps) => {
  return (
    <div>
      <label htmlFor={props.id} className='font-open_sans font-normal'>
        <div className='flex'>
          {props.hint.toUpperCase()}
          {props.hintDetail && (
            <img
              className='ml-[4px] cursor-pointer'
              id={props.hintDetail.id}
              src={props.hintDetail.icon}
              alt=''
            />
          )}
          {props.hintDetail && (
            <ReactTooltip anchorId={props.hintDetail.id} place='top'>
              <span className='flex flex-wrap w-[100px]'>{props.hintDetail.content}</span>
            </ReactTooltip>
          )}
        </div>
        <input
          className={[
            "h-12 pl-[12px] w-full mb-8 mt-[12px] rounded-[8px] outline-0 " +
              props.bgColor +
              " " +
              (props.error && "focus:border-red focus:border-[3px]"),
          ].join(" ")}
          type={props.type}
          name={props.id}
          value={props.value?.[props.id as "name" | "email" | "phone" | "link" | "description"]}
          onChange={(e) => {
            if (props.id == "phone") {
              const re = /^[0-9\b]+$/
              // if value is not blank, then test the regex

              if (
                (e.target.value === "" || re.test(e.target.value)) &&
                props.value &&
                props.setValue
              ) {
                props.setValue({ ...props.value, [props.id]: e.target.value })
              }
            } else {
              if (props.id == "name" && props.value && props.setValue) {
                const re = /[^a-z ]/gi
                // if value is not blank, then test the regex

                props.setValue({ ...props.value, [props.id]: e.target.value.replace(re, "") })
              } else if (props.value && props.setValue) {
                props.setValue({ ...props.value, [props.id]: e.target.value })
              }
            }
            props.validateFunc(e.target.value)
          }}
        />
      </label>
      {props.error && <p className='text-red-500 text-xs mt-[-20px] mb-[20px]'>{props.error}</p>}
    </div>
  )
}

export default TextField
