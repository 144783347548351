import React from "react"
import "./CareerBanner.css"
import data from "../../../../constants/contents/career.json"

type CareerBannerProps = {
  SetLocation: (selectedData: { id: string; check: boolean }[]) => void
  SetCategory: (selectedData: { id: string; check: boolean }[]) => void
  data: typeof data
  selectionControl: {
    id: number
    select: boolean
    checkbox: {
      id: string
      check: boolean
    }[]
  }[]
  setSelectionControl: (
    data: {
      id: number
      select: boolean
      checkbox: {
        id: string
        check: boolean
      }[]
    }[],
  ) => void
}

const CareerBanner = (props: CareerBannerProps) => {
  const [CAREERS_LOCATION] = React.useState(
    props.data.module.positions
      .map((item) => item.address)
      .filter((val, id, array) => array.indexOf(val) == id),
  )

  const [CAREERS_CATEGORY] = React.useState(
    props.data.module.positions
      .map((item) => item.role)
      .filter((val, id, array) => array.indexOf(val) == id),
  )

  const selectionControl = props.selectionControl
  const setSelectionControl = props.setSelectionControl

  const [mobileFilter, setMobileFilter] = React.useState(false)

  const CalculateAmount = (id: number, item: string) => {
    if (id == 0) {
      return props.data.module.positions.filter((c) => c.address == item).length
    } else {
      return props.data.module.positions.filter((c) => c.role == item).length
    }
  }

  const newCareer = props.data.module.positions.map((item) => {
    if (
      selectionControl.find((e) => e.id == 1)?.checkbox.find((e) => e.id == item.role)?.check ==
        false &&
      selectionControl.find((e) => e.id == 1)?.checkbox.filter((e) => e.check == false).length !=
        selectionControl.find((e) => e.id == 1)?.checkbox.length
    ) {
      return null
    }
    if (
      selectionControl.find((e) => e.id == 0)?.checkbox.find((e) => e.id == item.address)?.check ==
        false &&
      selectionControl.find((e) => e.id == 0)?.checkbox.filter((e) => e.check == false).length !=
        selectionControl.find((e) => e.id == 0)?.checkbox.length
    ) {
      return null
    }
    // setCountRole(countRole + 1)
    return item
  })

  // calculate total jobs
  const countCareer = () => {
    return newCareer.filter((e) => e != null).length
  }

  // total selected
  const atLeastsSelected = () => {
    const lengthOfCa = selectionControl
      .find((e) => e.id == 1)
      ?.checkbox.filter((e) => e.check == true).length
    const lengthOfLo = selectionControl
      .find((e) => e.id == 0)
      ?.checkbox.filter((e) => e.check == true).length
    const total = (lengthOfCa ? lengthOfCa : 0) + (lengthOfLo ? lengthOfLo : 0)
    return total > 0
  }

  // get name of each selected
  const allSelectedName = () => {
    const result: string[] = []

    selectionControl
      .find((e) => e.id == 1)
      ?.checkbox.filter((e) => e.check == true)
      .map((e) => {
        result.push(e.id)
      })

    selectionControl
      .find((e) => e.id == 0)
      ?.checkbox.filter((e) => e.check == true)
      .map((e) => {
        result.push(e.id)
      })

    return result
  }

  const CAREER_SELECTION = (id: number, listOptions: string[], props: CareerBannerProps) => {
    return (
      <>
        <div
          className={
            "bg-white tablets:border-[1px] relative font-pt_serif" +
            " rounded-[32px] bg-no-repeat bg-right py-[12px]" +
            " px-[16px] bg-origin-content career-selection " +
            (selectionControl.find((e) => e.id == id)?.select == true
              ? "bg-[url('../public/assets/career/caret-up.svg')]"
              : "bg-[url('../public/assets/career/caret-down.svg')]")
          }
          onClick={() => {
            setSelectionControl(
              selectionControl.map((e) => {
                if (e.id == id) {
                  return {
                    ...e,
                    select: !e.select,
                  }
                }
                return e
              }),
            )
          }}
        >
          {(id == 0 ? "Location" : "Category") +
            " (" +
            selectionControl.find((e) => e.id == id)?.checkbox.length +
            ")"}
        </div>
        <div
          className={
            "tablets:absolute w-[268px] bg-white tablets:border-[1px] mt-[10px] rounded-[16px] pt-[12px] px-[16px] " +
            (selectionControl.find((e) => e.id == id)?.select == true ? "flex flex-col" : "hidden")
          }
        >
          {listOptions.map((item, index) => {
            return (
              <label key={index} className='text-[16px] text-black mb-[12px] font-pt_serif'>
                <input
                  type='checkbox'
                  checked={
                    selectionControl.find((e) => e.id == id)?.checkbox.find((e) => e.id == item)
                      ?.check
                  }
                  className='mr-[4px] text-black'
                  onChange={() => {
                    setSelectionControl(
                      selectionControl.map((e) => {
                        return {
                          ...e,
                          checkbox: e.checkbox.map((c) => {
                            return {
                              ...c,
                              check: c.id == item ? !c.check : c.check,
                            }
                          }),
                        }
                      }),
                    )
                    selectionControl.map((e) => {
                      if (e.id == 0) {
                        props.SetLocation(
                          e.checkbox.map((c) => {
                            return {
                              ...c,
                              check: c.id == item ? !c.check : c.check,
                            }
                          }),
                        )
                      } else {
                        props.SetCategory(
                          e.checkbox.map((c) => {
                            return {
                              ...c,
                              check: c.id == item ? !c.check : c.check,
                            }
                          }),
                        )
                      }
                    })
                  }}
                />
                {item + " (" + CalculateAmount(id, item) + ")"}
              </label>
            )
          })}
        </div>
      </>
    )
  }

  const clearAll = () => {
    setSelectionControl(
      selectionControl.map((e) => {
        return {
          ...e,
          select: false,
          checkbox: e.checkbox.map((c) => {
            return {
              ...c,
              check: false,
            }
          }),
        }
      }),
    )

    props.SetLocation([])
    props.SetCategory([])
  }

  return (
    <div className='career-banner'>
      <div
        className={
          mobileFilter
            ? "w-screen h-screen z-10 mb-[48px] fixed bg-background top-0 tablets:hidden"
            : "hidden"
        }
      >
        <div className='flex pl-[16px] pr-[16px] mt-[18px] mb-[30px] justify-between'>
          <img
            className='cursor-pointer'
            src='./assets/back-arrow.svg'
            alt=''
            onClick={() => setMobileFilter(false)}
          />
          <h5 className='text-[16px]'>Filters</h5>
          <p className='mr-[32px] font-normal cursor-pointer' onClick={() => clearAll()}>
            Clear all
          </p>
        </div>
        <div className='mr-[36px] cursor-pointer'>
          {CAREER_SELECTION(0, [...CAREERS_LOCATION], props)}
        </div>
        <div className='mr-[36px] cursor-pointer'>
          {CAREER_SELECTION(1, [...CAREERS_CATEGORY], props)}
        </div>
        <div className='w-[100%] pr-[32px] text-center absolute bottom-0'>
          <button
            className='w-full bg-[#5C3BDE] rounded-[24px] text-white py-[12px] mb-[20px]'
            onClick={() => setMobileFilter(false)}
          >
            See jobs {"(" + countCareer() + ")"}
          </button>
        </div>
      </div>
      <section className='items-center'>
        <h3 className='mb-[48px] font-montserrat'>{props.data.banner.title}</h3>
        <div className='tablets:flex-row hidden tablets:flex tablets:mb-[48px]'>
          <div className='mr-[36px] cursor-pointer'>
            {CAREER_SELECTION(0, [...CAREERS_LOCATION], props)}
          </div>
          <div className='mr-[36px] cursor-pointer'>
            {CAREER_SELECTION(1, [...CAREERS_CATEGORY], props)}
          </div>
        </div>
        <div className='career-banner__mobile'>
          <div className='flex items-center'>
            <p className='font-[400px] mr-[12px]'>Filter</p>
            <img
              src='./assets/career/filter-black.svg'
              onClick={() => {
                setMobileFilter(true)
              }}
              className='rounded-[32px] p-[10px] border-[1px] cursor-pointer border-[#E6E8EC]'
              alt='filter'
            />
          </div>
        </div>
      </section>
      <div className={atLeastsSelected() ? "flex mb-[48px] justify-center" : "hidden"}>
        <div className='flex mr-[4px]'>
          <p className='font-bold mr-[4px]'>{countCareer().toString()}</p>
          <p>{"jobs"}</p>
        </div>
        <div className='flex flex-wrap'>
          {allSelectedName().map((e, index) => {
            if (index < allSelectedName().length - 1) {
              return (
                <p key={index} className='mr-[4px]'>
                  in<span className='font-bold ml-[4px]'>{e}</span> &
                </p>
              )
            }
            return (
              <p key={index} className='flex'>
                in <span className='font-bold ml-[4px] mr-[4px]'>{e}</span>
                <img
                  src='./assets/close-button.svg'
                  className='cursor-pointer'
                  onClick={() => clearAll()}
                  alt=''
                />
              </p>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default CareerBanner
