import React from "react"
import { FOLLOW_US, FOOTER_CONTENTS, FOOTER_ADDRESS } from "../../../../constants/strings/footer"
import { MENUS } from "../../../../constants/strings/navbar"
import { AiOutlineEnvironment } from "react-icons/ai"
import CAREERS_CONTENT from "../../../../constants/contents/career.json"
import "./Footer.css"

interface FooterProps {
  deviceType: "phone" | "tablet" | "desktop" | "large-desktop"
}

const Footer = ({ deviceType }: FooterProps) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <div className='footer-v2'>
      <div className='footer-v2__information'>
        {(deviceType == "desktop" || deviceType === "large-desktop") && (
          <div
            className='footer-v2__icon bg-black w-[56px] h-[88px] rounded-[44px] relative cursor-pointer'
            onClick={scrollToTop}
          >
            <img
              className='left-1/2 absolute -translate-x-1/2 translate-y-[30px]'
              src='./assets/footer-arrow.svg'
              alt='arrow'
            />
            <img
              className='top-[86px] left-1/2 absolute -translate-x-1/2'
              src='./assets/footer-arrow.svg'
              alt='arrow'
            />
          </div>
        )}
        <div className='flex tablets:flex-row flex-col justify-between gap-4 w-full'>
          <div className='mb-[48px] tablets:mb-0'>
            <div className='tablets:flex-ro mb-[24px] small-desktops:flex justify-between w-full flex flex-col gap-4'>
              <div className='flex gap-1 h-fit tablets:hidden'>
                <img src='./assets/message.svg' alt='message' />
                <a
                  href='mailto:hello@deltacognition.com'
                  className='border-b border-transparent hover:border-primary body-text-1--regular'
                >
                  hello@deltacognition.com
                </a>
              </div>
              <span className='font-work_sans'>
                We apply artificial intelligence to next-gen problems.
              </span>
            </div>

            <div className='flex tablets:flex-col tablets:gap-6'>
              <ul className='w-[168px] gap-8 flex flex-col tablets:flex-row tablets:w-full'>
                {MENUS.map((item, index) => {
                  return (
                    <li key={index} className='body-text-1--semi-bold flex'>
                      <a href={item.route}>{item.label}</a>
                      <p
                        className={
                          item.label == "Careers"
                            ? "ml-1 font-normal font-work_sans text-white text-[12px] bg-primary rounded-[50px] pl-[4px] w-[24px] h-[24px]"
                            : "hidden"
                        }
                      >
                        {item.label == "Careers"
                          ? CAREERS_CONTENT.module.positions.length + "+"
                          : ""}
                      </p>
                    </li>
                  )
                })}
              </ul>
              <ul className='w-[159px] gap-8 flex flex-col tablets:flex-row tablets:w-full items-center '>
                <li className='font-[600] font-work_sans'>Follow us</li>
                {FOLLOW_US.map((item, index) => {
                  let iconSource = ""
                  if (item.label === "LinkedIn") {
                    iconSource = "./assets/footer/LinkedIn.svg"
                  }
                  return (
                    <li key={index} className='body-text-1--regular'>
                      <a
                        href={item.route}
                        className='flex items-center gap-1'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img src={iconSource} alt='icon' className='h-6' />
                        {item.label}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className='w-full tablets:w-[350px] small-desktops:w-[450px]'>
            <div className='gap-1 h-fit mb-[24px] hidden tablets:flex'>
              <img src='./assets/message.svg' alt='message' />
              <a
                href='mailto:hello@deltacognition.com'
                className='border-b border-transparent hover:border-primary body-text-1--regular'
              >
                hello@deltacognition.com
              </a>
            </div>
            <div className='mb-[24px] tablets:hidden'>
              <h5>Delta Cognition</h5>
            </div>
            {FOOTER_ADDRESS.map((item, index) => {
              return (
                <a
                  key={index}
                  className='border-b border-transparent hover:border-primary body-text-1--regular overflow-hidden w-full block mb-4 ml-[4px]'
                >
                  <AiOutlineEnvironment className='inline-block' /> {item.label}
                </a>
              )
            })}
          </div>
        </div>
      </div>
      <hr className='border-t' />
      <div className='footer-v2_privacy'>
        <span className='body-text-1--regular tablets:grow'>
          {FOOTER_CONTENTS.find((item) => item.label === "Company")?.route}
        </span>
        <a
          href='#'
          className='body-text-1--regular border-b border-transparent hover:border-primary'
        >
          {FOOTER_CONTENTS.find((item) => item.label === "Terms of Use")?.label}
        </a>
        <a
          href='#'
          className='body-text-1--regular border-b border-transparent hover:border-primary'
        >
          {FOOTER_CONTENTS.find((item) => item.label === "Privacy Policy")?.label}
        </a>
        <div className='flex gap-1'>
          <span className='body-text-1--regular'>
            {FOOTER_CONTENTS.find((item) => item.label === "Language")?.route}
          </span>
        </div>
      </div>
      <img
        className='absolute right-[30px] bottom-[15px] cursor-pointer tablets:top-[-20px] small-desktops:hidden'
        src='./assets/arrow-up-circle.svg'
        alt='arrow'
        onClick={scrollToTop}
      />
    </div>
  )
}

export default Footer
