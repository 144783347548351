import React from "react"
import JobCard, { JobCardData } from "../../../../components/v0.1/0.1.1/JobCard/JobCard"
import RoleDetail from "../RoleDetail/RoleDetail"
import "./ApplicationModule.css"
import data from "../../../../constants/contents/career.json"

interface ApplicationModuleProps {
  apply: boolean
  location: { id: string; check: boolean }[]
  category: { id: string; check: boolean }[]
  onApply: (status: boolean) => void
  data: typeof data
}

const ApplicationModule = (props: ApplicationModuleProps) => {
  const [roleDetail, setRoleDetail] = React.useState<{
    id: number
    title: string
    role: string
    address: string
  }>()

  const newCareer = props.data.module.positions.map((item) => {
    if (
      props.category.find((e) => e.id == item.role)?.check == false &&
      props.category.filter((e) => e.check == false).length != props.category.length
    ) {
      return null
    }
    if (
      props.location.find((e) => e.id == item.address)?.check == false &&
      props.location.filter((e) => e.check == false).length != props.location.length
    ) {
      return null
    }
    // setCountRole(countRole + 1)
    return item
  })

  // calculate total jobs
  const countCareer = () => {
    return newCareer.filter((e) => e != null).length
  }

  return (
    <div className='application-module'>
      {!props.apply && (
        <div className={"flex flex-col " + (countCareer() > 0 ? "border-t border-gray" : "")}>
          {newCareer.length > 0 &&
            newCareer.map((item, index) => {
              // setCountRole(countRole + 1)
              if (item != null) {
                return (
                  <div key={index} className={"border-b module__jobcard"}>
                    <JobCard
                      onApply={props.onApply}
                      setRoleDetail={setRoleDetail}
                      data={item as JobCardData}
                    />
                  </div>
                )
              }
            })}
          <div className='mt-[60px] mb-12 text-center w-full'>
            <p className='body-text-1--semi-bold'>{props.data.footer.title}</p>
            <p className='body-text-1--regular'>
              Send an email to{" "}
              <a href={"mailto:" + props.data.footer.email} className='text-primary'>
                {props.data.footer.email}
              </a>{" "}
              and let&apos;s talk!
            </p>
          </div>
        </div>
      )}

      {props.apply && <RoleDetail roleDetail={roleDetail} onApply={props.onApply} />}
    </div>
  )
}

export default ApplicationModule
