import React from "react"
import "./NavBar.css"
import { FOLLOW_US, FOOTER_CONTENTS } from "../../../../constants/strings/footer"
import { MENUS } from "../../../../constants/strings/navbar"

interface NavBarProps {
  selectedPage: string
  deviceType: "phone" | "tablet" | "desktop" | "large-desktop"
}

const NavBar = (props: NavBarProps) => {
  const [openMenu, setOpenMenu] = React.useState(false)

  const onOpenMenu = () => {
    // prevent scrolling
    if (openMenu === false) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "scroll"
    }
    setOpenMenu(!openMenu)
  }

  return (
    <nav className='nav-bar'>
      <a href='/'>
        <img className='nav-bar__logo' src='./assets/logo.svg' alt='company-logo' />
      </a>
      {(props.deviceType === "phone" || props.deviceType === "tablet") && (
        <div className='nav-bar__navigation--phone'>
          {openMenu ? (
            <img src='./assets/close-button.svg' alt='close-button' onClick={onOpenMenu} />
          ) : (
            <img src='./assets/hamburger-button.svg' alt='hamburger-button' onClick={onOpenMenu} />
          )}
          {openMenu && (
            <div className='navigation--phone__open-menu'>
              {MENUS.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={props.selectedPage == item.route ? "text-primary" : ""}
                  >
                    <a href={item.route}>{item.label}</a>
                  </li>
                )
              })}
              <hr />
              {FOLLOW_US.map((item, index) => {
                return (
                  <li key={index}>
                    <a href={item.route}>{item.label}</a>
                  </li>
                )
              })}
              <hr />
              {FOOTER_CONTENTS.map((item, index) => {
                if (item.label === "Language" || item.label === "Company") {
                  return (
                    <li key={index} className='flex gap-1'>
                      <span className='body-text-1--regular'>{item.route}</span>{" "}
                      {item.label === "Language" && item.route === "English" && (
                        <img src='./assets/uk-flag.svg' alt='flag' />
                      )}
                    </li>
                  )
                } else {
                  return (
                    <li key={index}>
                      <a href={item.route}>{item.label}</a>
                    </li>
                  )
                }
              })}
            </div>
          )}
        </div>
      )}
      {(props.deviceType === "desktop" || props.deviceType === "large-desktop") && (
        <div className='nav-bar__navigation--desktop'>
          {MENUS.map((item, index) => {
            return (
              <li key={index} className={props.selectedPage == item.route ? "text-primary" : ""}>
                <a href={item.route}>{item.label}</a>
              </li>
            )
          })}
        </div>
      )}
    </nav>
  )
}

export default NavBar
