import React from "react"

type WindowSize = {
  width: number
  height: number
}
const useWindowSize = () => {
  const [size, setSize] = React.useState<WindowSize>({
    width: 0,
    height: 0,
  })

  React.useEffect(() => {
    const handleSize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener("resize", handleSize)
    handleSize()
    return () => window.removeEventListener("resize", handleSize)
  }, [])
  return size
}

export default useWindowSize
