import React from "react"
import "./AIPower.css"
import Wrapper from "../../../../components/v0.1/0.1.1/Wrapper/Wrapper"
import ScrollContainer from "react-indiana-drag-scroll"
import data from "../../../../constants/contents/ai-power.json"

const AIPower = (props: typeof data) => {
  const iconUrl = "./assets/wrapper-icon.svg"

  return (
    <div className='ai-power-container'>
      <section>
        <h2 className='font-montserrat text-2xl leading-8 tablets:text-base'>{props.title}</h2>
        <div className='w-full'>
          <div className='ai-power-container__values'>
            {props.contents.map((item, index) => {
              return (
                <Wrapper
                  key={index}
                  iconUrl={iconUrl}
                  title={item.title}
                  detail={item.detail}
                  image={item.image}
                />
              )
            })}
          </div>
          <ScrollContainer className='w-full px-4'>
            <div className='pt-[48px] max-w-[1440px] w-[290px] m-auto grid grid-flow-col gap-x-[48px] tablets:hidden'>
              {props.contents.map((item, index) => {
                return (
                  <Wrapper
                    key={index}
                    iconUrl={iconUrl}
                    title={item.title}
                    detail={item.detail}
                    image={item.image}
                  />
                )
              })}
            </div>
          </ScrollContainer>
        </div>
      </section>
    </div>
  )
}

export default AIPower
