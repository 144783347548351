import React from "react"
import "./Introduction.css"

import AOS from "aos"
import "aos/dist/aos.css"
import ScrollContainer from "react-indiana-drag-scroll"
import data from "../../../../constants/contents/introduction.json"

const Introduction = (props: typeof data) => {
  React.useEffect(() => {
    AOS.init()
  }, [])

  return (
    <div className='introduction-v2'>
      <div className='relative'>
        <div
          className='introduction-v2__title'
          data-aos='fade-right'
          data-aos-easing='ease'
          data-aos-delay='1500'
        >
          <h3>{props.title}</h3>
          <h6>{props.description}</h6>
        </div>
        <img
          src={props.image.mobile}
          alt='image'
          className='tablets:hidden w-full'
          data-aos='zoom-in'
          data-aos-easing='ease'
          data-aos-delay='500'
        />
        <img
          src={props.image.tablets}
          alt='image'
          className='hidden tablets:block small-desktops:hidden w-full'
          data-aos='zoom-in'
          data-aos-easing='ease'
          data-aos-delay='500'
        />
        <img
          src={props.image.desktops}
          alt='image'
          className='hidden small-desktops:block large-desktops:hidden w-full'
          data-aos='zoom-in'
          data-aos-easing='ease'
          data-aos-delay='500'
        />
        <img
          src={props.image["large-desktops"]}
          alt='image'
          className='hidden large-desktops:block w-full'
          data-aos='zoom-in'
          data-aos-easing='ease'
          data-aos-delay='500'
        />
      </div>
      <ScrollContainer>
        <div
          className='scroll-container'
          data-aos='fade-up'
          data-aos-easing='ease'
          data-aos-delay='1000'
        >
          {props.contents.map((item) => {
            return (
              <div key={item.label} className='scroll-container__card'>
                <img src={item.image} alt='icon' className='w-[45px]' />
                <h5 className='text-[20px] font-montserrat font-bold'>{item.label}</h5>
                <span className='text-[14px] text-center text-[#6B7181] font-open_sans font-normal'>
                  {item.value}
                </span>
              </div>
            )
          })}
        </div>
      </ScrollContainer>
    </div>
  )
}

export default Introduction
