import React from "react"
import "./App.css"
import useWindowSize from "./utils/useWindowSize"
import { Routes, Route, useLocation } from "react-router-dom"
import { OPEN_LANDING_PAGE, NODE_ENV } from "./constants/configs"

import NavBar from "./components/v0.1/0.1.1/NavBar/NavBar"
import HomePage from "./pages/HomePage/HomePage"
import CareerPage from "./pages/CareerPage/CareerPage"
import Footer from "./components/v0.1/0.1.1/Footer/Footer"
import Login from "./components/v0.1/0.1.1/Login/Login"

const App = () => {
  const [deviceType, setDeviceType] = React.useState<
    "phone" | "tablet" | "desktop" | "large-desktop"
  >("phone")

  const location = useLocation()

  const windowSize = useWindowSize()

  const [isAuth] = React.useState<boolean>(sessionStorage.getItem("isAuth") === "true" || false)

  React.useEffect(() => {
    if (windowSize.width > 1200) {
      setDeviceType("large-desktop")
    } else if (windowSize.width > 992) {
      setDeviceType("desktop")
    } else if (windowSize.width > 768) {
      setDeviceType("tablet")
    } else if (windowSize.width > 320) {
      setDeviceType("phone")
    }
  }, [windowSize])

  return (
    <div className='app'>
      {OPEN_LANDING_PAGE === "false" && (NODE_ENV === "production" || isAuth) && (
        <header className='app__header'>
          <NavBar selectedPage={location.pathname} deviceType={deviceType} />
        </header>
      )}
      <main className='app__container'>
        <Routes>
          <Route
            index
            path='/'
            element={
              NODE_ENV === "development" && !isAuth ? (
                <div className='flex items-center justify-center h-[60vh]'>
                  <Login />
                </div>
              ) : (
                <HomePage />
              )
            }
          />
          {OPEN_LANDING_PAGE === "false" && (NODE_ENV === "production" || isAuth) && (
            <>
              <Route path='/careers' element={<CareerPage />} />
            </>
          )}
          <Route
            path='*'
            element={
              NODE_ENV === "development" && !isAuth ? (
                <div className='flex items-center justify-center h-[60vh]'>
                  <Login />
                </div>
              ) : (
                <HomePage />
              )
            }
          />
        </Routes>
      </main>
      {OPEN_LANDING_PAGE === "false" && (NODE_ENV === "production" || isAuth) && (
        <footer className='app__footer'>
          <Footer deviceType={deviceType} />
        </footer>
      )}
    </div>
  )
}

export default App
