// we need company static detail, for later manage
export const COMPANY_DETAIL = {
  name: "delta cognition",
  iconUrl: "./assets/landing-page/landing-page-logo.svg",
  contentUrl: "./assets/landing-page/landing-page-image.png",
  slogansTitle: "We apply AI to next-gen problems",
  slogansDetail: [
    <span key={1}>
      <strong>Delta Cognition </strong> is a team of hardworking, dedicated AI enthusiasts committed
      to finding solutions to the world&apos;s most pressing issues through the power of AI.
    </span>,
    <span key={2}>
      We are currently looking for highly motivated machine learning engineers, full-stack
      developers (both mobile & web), UI/UX designers/developers, blockchain engineers and energetic
      interns. If you are excited to join our team and would like to learn more, we would love to
      hear from you.
    </span>,
  ],
  email: "hello@deltacognition.com",
}

export const DETAIL_POINTS = {
  iconUrl: "./assets/landing-page/Pointer.svg",
  detail: ["Say hello to us at", "or leave you email below and we will be in touch"],
}

export const CONTACTUS = {
  inputHint: "Enter your email",
  buttonText: "Contact us",
}

// FOOTER
export const FOOTER = {
  iconUrl: "./assets/landing-page/LinkedIn.svg",
  link: "https://www.linkedin.com/company/delta-cognition/",
  detail: "Future proof AI",
  copyRight: "© 2022 by Delta Cognition",
}
