import React from "react"
import "./Successful.css"

interface SuccessfulProps {
  title: string
  content: string
  firstButtonName: string
  secondButtonname: string
  firstButton: () => void
  secondButton: () => void
}

const Successful = (props: SuccessfulProps) => {
  return (
    <div className='successful-container'>
      <h5 className='successful-container__title'>{props.title}</h5>
      <p className='successful-container__content'>{props.content}</p>
      <div className='successful-container__buttons'>
        <button
          className='mr-[16px] mb-[16px] tablets:mb-0 bg-[#5C3BDE] text-white text-[16px]'
          onClick={props.firstButton}
        >
          {props.firstButtonName}
        </button>
        <button
          className='bg-white text-[#5C3BDE] border-[2px] text-[16px] border-[#5C3BDE]'
          onClick={props.secondButton}
        >
          {props.secondButtonname}
        </button>
      </div>
    </div>
  )
}

export default Successful
