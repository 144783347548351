import React from "react"
import "./Login.css"

import { login } from "../../../../services/auth"

const Login = () => {
  const [input, setInput] = React.useState<{
    userName: string
    password: string
  }>({
    userName: "",
    password: "",
  })
  const [message, setMessage] = React.useState<string>("")

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target
    setInput({ ...input, [id]: value })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    login(input).then((res) => {
      if (res.status === 200) {
        sessionStorage.setItem("isAuth", "true")
        window.location.reload()
      } else {
        setMessage("Authentication failed. Try again.")
      }
    })
  }

  return (
    <div className='login'>
      <h2 className='text-center mb-4 text-white'>Login Form</h2>
      <form className='m-auto' onSubmit={onSubmit}>
        <div className='mb-3'>
          <label htmlFor='userName' className='text-white text-lg w-[100px] inline-block'>
            Username
          </label>
          <input type='text' required className='px-2 py-[2px]' id='userName' onChange={onChange} />
        </div>
        <div className='mb-3'>
          <label htmlFor='password' className='text-white text-lg w-[100px] inline-block'>
            Password
          </label>
          <input
            type='password'
            required
            className='px-2 py-[2px]'
            id='password'
            onChange={onChange}
          />
        </div>
        <div className='flex'>
          <button className='m-auto bg-white border rounded-lg w-[100px]' type='submit'>
            Login
          </button>
        </div>
        {<p className='text-red-500 text-center font-bold h-[24px]'>{message}</p>}
      </form>
    </div>
  )
}

export default Login
