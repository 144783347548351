import React from "react"
import "./JobCard.css"

export type JobCardData = {
  id: number
  title: string
  role: string
  address: string
}

interface JobCardProps {
  data: JobCardData
  onApply: (status: boolean) => void
  setRoleDetail: React.Dispatch<
    React.SetStateAction<
      | {
          id: number
          title: string
          role: string
          address: string
        }
      | undefined
    >
  >
}

const JobCard = (props: JobCardProps) => {
  return (
    <div className='job-card'>
      <div className='flex justify-between props.datas-center flex-col tablets:flex-row gap-2'>
        <div className='flex flex-col tablets:flex-row gap-2'>
          <h4 className='font-pt_serif !text-[20px] tablets:min-w-[312px] tablets:max-w-[800px] w-fit'>
            {props.data.title}
          </h4>
          <div className='tablets:w-[213px] '>
            <span className='body-text-1--regular rounded-[16px] border-[1px] py-[6px] px-[12px] w-fit text-[14px] m-auto'>
              {props.data.role}
            </span>
          </div>

          <span className='body-text-1--regular w-fit tablets:m-auto'>{props.data.address}</span>
        </div>
        <div className='flex items-center tablets:justify-center'>
          <button
            className='apply-btn'
            onClick={() => {
              props.onApply(true)
              props.setRoleDetail(props.data)
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  )
}

export default JobCard
