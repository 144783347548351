export const MENUS = [
  // {
  //   label: "Our people",
  //   route: "#",
  // },
  // {
  //   label: "Our work",
  //   route: "#",
  // },
  // {
  //   label: "Blogs",
  //   route: "#",
  // },
  {
    label: "Home",
    route: "/",
  },
  {
    label: "Careers",
    route: "/careers",
  },
]
