import React, { FC } from "react"
import ScrollContainer from "react-indiana-drag-scroll"
import "./RoleDetailTab.css"

type RoleDetailTabsProps = {
  tabs: {
    label: string
    index: number
    Component: FC<{ index: number }>
  }[]
  selectedTab: number
  onClick: (index: number) => void
  orientation?: "horizontal" | "vertical"
  className?: string
  applyCondition: boolean
}

const RoleDetailTab: FC<RoleDetailTabsProps> = ({
  className = "tabs-component",
  tabs = [],
  selectedTab = 0,
  onClick,
  orientation = "horizontal",
}) => {
  const Panel = tabs && tabs.find((tab) => tab.index === selectedTab)

  return (
    <div className={orientation === "vertical" ? className + " vertical" : className}>
      <div className='tab-container' role='tablist' aria-orientation={orientation}>
        <ScrollContainer className='block tablets:hidden tab-container__labels'>
          <div className='flex justify-start tab-buttons'>
            {tabs.map((tab) => (
              <button
                className={
                  "min-w-fit font-work_sans " + (selectedTab === tab.index ? "active" : "")
                }
                onClick={() => onClick(tab.index)}
                key={tab.index}
                type='button'
                role='tab'
                aria-selected={selectedTab === tab.index}
                aria-controls={`tabpanel-${tab.index}`}
                tabIndex={selectedTab === tab.index ? 0 : -1}
                id={`btn-${tab.index}`}
              >
                {tab.label}
              </button>
            ))}
          </div>
        </ScrollContainer>
        <div className='tab-container__labels'>
          <div className='tablets:justify-start tablets:tab-buttons hidden tablets:w-full tablets:flex'>
            {tabs.map((tab) => (
              <button
                className={
                  "min-w-fit font-work_sans " + (selectedTab === tab.index ? "active" : "")
                }
                onClick={() => onClick(tab.index)}
                key={tab.index}
                type='button'
                role='tab'
                aria-selected={selectedTab === tab.index}
                aria-controls={`tabpanel-${tab.index}`}
                tabIndex={selectedTab === tab.index ? 0 : -1}
                id={`btn-${tab.index}`}
              >
                {tab.label}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div
        role='tabpanel'
        className='bg-[#F4F5F6] px-[31px] pt-[48px] pb-[120px] w-full flex justify-center absolute left-0'
        aria-labelledby={`btn-${selectedTab}`}
        id={`tabpanel-${selectedTab}`}
      >
        <div className='tabpanel-fixed'>{Panel && <Panel.Component index={selectedTab} />}</div>
      </div>
      <div className='w-full pb-[120px]'>{Panel && <Panel.Component index={selectedTab} />}</div>
    </div>
  )
}
export default RoleDetailTab
